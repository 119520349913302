import classNames from "classnames"
import { graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import React from "react"
import { IPage } from "../../@types/types"
import Layout from "../components/Layout"
import PageSection from "../components/PageSection"
import Seo from "../components/Seo"

const Page = ({ data }: { data: { contentfulPage: IPage } }): JSX.Element => {
  const {
    contentfulPage: { title, description, body, sections },
  } = data

  const className = classNames("container mx-auto html pt-8", {
    "pb-12": !sections?.length,
  })

  return (
    <Layout>
      {({ handleAction }) => (
        <>
          <Seo title={title} description={description} />
          <div className={className}>
            <h1>{title}</h1>
            {body && renderRichText(body)}
          </div>
          {sections &&
            sections.map(({ id, ...props }, index) => (
              <PageSection
                key={id}
                id={id}
                {...props}
                index={index}
                onAction={handleAction}
              />
            ))}
        </>
      )}
    </Layout>
  )
}

export default Page

export const query = graphql`
  query PageQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      title
      description
      body {
        raw
      }
      sections {
        id
        title
        content {
          __typename
          ... on ContentfulHeroSection {
            id
            orientation
            heading1
            heading2
            introduction {
              childMarkdownRemark {
                html
              }
            }
            heroImage {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            callsToAction {
              id
              text
              url
              action {
                event
                arguments
              }
              background
            }
          }
          ... on ContentfulFeatureSection {
            title
            heading
            description {
              childMarkdownRemark {
                html
              }
            }
            features {
              id
              title
              icon
              description {
                description
              }
            }
          }
          ... on ContentfulTestimonialSection {
            testimonial {
              id
              cite
              quote {
                quote
              }
              image {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          ... on ContentfulMarkdownSection {
            id
            content {
              childMarkdownRemark {
                html
              }
            }
            callsToAction {
              id
              text
              url
              action {
                event
                arguments
              }
              background
            }
          }
          ... on ContentfulCtaSection {
            id
            callsToAction {
              id
              text
              url
              action {
                event
                arguments
              }
              background
            }
          }
        }
      }
    }
  }
`
